import { render, staticRenderFns } from "./saleDayNew.vue?vue&type=template&id=3209f537&"
import script from "./saleDayNew.vue?vue&type=script&lang=js&"
export * from "./saleDayNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VRadio } from 'vuetify/lib'
import { VRadioGroup } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VStepper } from 'vuetify/lib'
import { VStepperContent } from 'vuetify/lib'
import { VStepperHeader } from 'vuetify/lib'
import { VStepperItems } from 'vuetify/lib'
import { VStepperStep } from 'vuetify/lib'
import { VSubheader } from 'vuetify/lib'
import { VSwitch } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardTitle,VCheckbox,VContainer,VContent,VDivider,VFlex,VImg,VLayout,VRadio,VRadioGroup,VSpacer,VStepper,VStepperContent,VStepperHeader,VStepperItems,VStepperStep,VSubheader,VSwitch,VTextField})
