<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title title="Rellene el formulario para completar el ingreso">
            <template slot="subtitle">
              El formulario cuenta con 3 pasos
            </template>
          </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">Producto</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">Detalle</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3">Fotos + Video</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="searchText"
                      append-icon="search"
                      placeholder="Buscar por nombre o codigo"
                      label="Producto"
                      box
                      @keyup.native="searchInputHandle"
                    ></v-text-field>
                    <v-card max-width="400" color="info" class="white--text" v-if="_.size(products) > 0">
                      <v-layout row>
                        <v-flex xs7>
                          <v-card-title primary-title>
                            <div>
                              <div class="headline" v-html="products.name"></div>
                              <div>P{{products.id}}</div>
                              <div>(2013)</div>
                            </div>
                          </v-card-title>
                        </v-flex>
                        <v-flex xs5 class="text-xs-right">
                          <v-img
                            :src="products.img.url"
                            contains
                            class="mr-2"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 class="text-xs-right">
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mx-0"
                      color="secondary"
                      depressed
                      :disabled="!(_.size(products) > 0)"
                      @click="step = 2">
                      Continuar
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-subheader>Día de la semana</v-subheader>
                    <v-radio-group hide-details v-model="product.day" row class="ma-0 mx-0">
                      <v-radio label="Lunes" value="1"></v-radio>
                      <v-radio label="Martes" value="2"></v-radio>
                      <v-radio label="Miercoles" value="3"></v-radio>
                      <v-radio label="Jueves" value="4"></v-radio>
                      <v-radio label="Viernes" value="5"></v-radio>
                      <v-radio label="Sabado" value="6"></v-radio>
                      <v-radio label="Domingo" value="0"></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12>
                    <v-subheader>Descripción</v-subheader>
                      <quill-editor v-model="product.description"
                        ref="myQuillEditor"
                        :options="editorOption">
                      </quill-editor>
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      v-model="product.quantity"
                      append-icon="dollar"
                      placeholder=""
                      type="number"
                      label="Cantidad a la venta"
                      box
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      v-model="product.price"
                      append-icon="dollar"
                      placeholder=""
                      type="number"
                      label="Precio"
                      box
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4>
                    <v-switch v-model="product.status" label="Activo"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-subheader>Flores</v-subheader>
                    <v-layout row wrap>
                      <v-flex xs12 sm4 md2 class="py-0" v-for="(flower, index) in flowers" :key="index">
                        <v-checkbox hide-details class="mt-0" v-model="product.flowers" :label="flower.name" :value="flower.id"></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="text-xs-right">
                    <v-spacer></v-spacer>
                    <v-btn @click="step = 1" flat>Volver</v-btn>
                    <v-btn
                      class="ma-0"
                      color="primary"
                      @click="step = 3">
                      Continuar
                    </v-btn>
                  </v-flex>

                </v-layout>

              </v-stepper-content>
              <v-stepper-content step="3">
                <v-layout wrap>
                  <v-flex xs3>
                    <img v-if="product.photo1" :src="product.photo1" style="max-width:100%;">
                    <input type="file" id="file" ref="photo1" v-on:change="handlePhotoUpload('photo1')" style="display: none">
                    <v-btn
                      v-if="!product.photo1"
                      color="primary"
                      block
                      depressed
                      @click="$refs.photo1.click()">
                      Subir Foto
                    </v-btn>
                    <v-btn
                      v-else
                      color="error"
                      block
                      depressed
                      @click="product.photo1 = ''">
                      Quitar Foto
                    </v-btn>
                  </v-flex>
                  <v-flex xs3>
                    <img v-if="product.photo2" :src="product.photo2" style="max-width:100%;">
                    <input type="file" id="file" ref="photo2" v-on:change="handlePhotoUpload('photo2')" style="display: none">
                    <v-btn
                      v-if="!product.photo2"
                      color="primary"
                      block
                      depressed
                      @click="$refs.photo2.click()">
                      Subir Foto
                    </v-btn>
                    <v-btn
                      v-else
                      color="error"
                      block
                      depressed
                      @click="product.photo2 = ''">
                      Quitar Foto
                    </v-btn>
                  </v-flex>
                  <v-flex xs3>
                    <img v-if="product.photo3" :src="product.photo3" style="max-width:100%;">
                    <input type="file" id="file" ref="photo3" v-on:change="handlePhotoUpload('photo3')" style="display: none">
                    <v-btn
                      v-if="!product.photo3"
                      color="primary"
                      block
                      depressed
                      @click="$refs.photo3.click()">
                      Subir Foto
                    </v-btn>
                    <v-btn
                      v-else
                      color="error"
                      block
                      depressed
                      @click="product.photo3 = ''">
                      Quitar Foto
                    </v-btn>
                  </v-flex>
                  <v-flex xs3>
                    <img v-if="product.photo4" :src="product.photo4" style="max-width:100%;">
                    <input type="file" id="file" ref="photo4" v-on:change="handlePhotoUpload('photo4')" style="display: none">
                    <v-btn
                      v-if="!product.photo4"
                      color="primary"
                      block
                      depressed
                      @click="$refs.photo4.click()">
                      Subir Foto
                    </v-btn>
                    <v-btn
                      v-else
                      color="error"
                      block
                      depressed
                      @click="product.photo4 = ''">
                      Quitar Foto
                    </v-btn>
                  </v-flex>
                  <v-flex xs8>
                    <v-text-field
                      v-model="product.video"
                      append-icon="link"
                      placeholder=""
                      type="text"
                      label="URL Video (Youtube)"
                      box
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4>
                    <img v-if="product.reference" :src="product.reference" style="max-width:100%;">
                    <input type="file" id="file" ref="reference" v-on:change="handlePhotoUpload('reference')" style="display: none">
                    <v-btn
                      v-if="!product.reference"
                      color="primary"
                      block
                      depressed
                      @click="$refs.reference.click()">
                      Foto referencia
                    </v-btn>
                    <v-btn
                      v-else
                      color="error"
                      block
                      depressed
                      @click="product.reference = ''">
                      Quitar Foto
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 class="text-xs-right">
                    <v-btn flat @click="step = 2">Volver</v-btn>
                    <v-btn
                      class="mx-0"
                      color="primary"
                      @click="sendProduct">
                      Finalizar
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import appTitle from '../useful/title.vue';
import { GET_API } from '../../config';

export default {
  name: 'SaleDayNew',
  components: { appTitle },
  props: ['id'],
  methods: {
    handlePhotoUpload(type) {
      this.product[type] = URL.createObjectURL(this.$refs[type].files[0]);
    },
    getProducts() {
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'getProductsStore',
          filter: this.productsFilter,
        },
      }).then((res) => {
        this.productsAll = res.data.json;
        if (this.id) this.getProductSale();
      });
    },
    getFlowers() {
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'getFlowers',
        },
      }).then((res) => {
        this.flowers = res.data.json;
      });
    },
    searchInputHandle() {
      const search = this.searchText;
      let result = this._.filter(this.productsAll, (item) => {
        const res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search));
        return res;
      });
      if (result.length === 0) {
        result = this._.filter(this.productsAll, (item) => {
          const res = this._.includes(this._.lowerCase(item.id), this._.lowerCase(search));
          return res;
        });
      }
      if (result.length > 0 && search !== '') {
        this.products = result[0];
      } else {
        this.products = {};
      }
      this.dialogSearch = false;
    },
    sendProduct() {
      const { product } = this;
      const formData = new FormData();

      formData.append('p', 'postProductSale');

      if (this.id) formData.append('id', this.id);

      formData.append('productID', this.products.id);
      formData.append('description', product.description);
      formData.append('price', product.price);
      formData.append('day', product.day);
      formData.append('video', product.video);
      formData.append('status', product.status);
      formData.append('quantity', product.quantity);
      formData.append('flowers', JSON.stringify(product.flowers));

      if (this.$refs.photo1.files[0]) formData.append('photo1', this.$refs.photo1.files[0]);

      if (this.$refs.photo2.files[0]) formData.append('photo2', this.$refs.photo2.files[0]);

      if (this.$refs.photo3.files[0]) formData.append('photo3', this.$refs.photo3.files[0]);

      if (this.$refs.photo4.files[0]) formData.append('photo4', this.$refs.photo4.files[0]);

      if (this.$refs.reference.files[0]) formData.append('reference', this.$refs.reference.files[0]);

      this.$http.post(GET_API, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        this.$router.push({ name: 'productsSaleDay' });
      });
    },
    getProductSale() {
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'getProductSaleDay',
          id: this.id,
        },
      }).then((res) => {
        const product = this._.filter(this.productsAll, ['id', res.data.json.product_id]);
        let flowers = [];
        this.products = product[0];
        if (res.data.json.flowers.length > 0) {
          flowers = res.data.json.flowers;
        }
        this.product = {
          detail: {},
          day: res.data.json.day,
          description: res.data.json.description,
          video: res.data.json.video,
          status: res.data.json.status,
          price: res.data.json.price,
          photo1: res.data.json.photo1,
          photo2: res.data.json.photo2,
          photo3: res.data.json.photo3,
          photo4: res.data.json.photo4,
          quantity: res.data.json.quantity,
          reference: res.data.json.photo_reference,
          flowers,
        };
      });
    },
  },
  data() {
    return {
      step: 0,
      productsAll: [],
      products: [],
      flowers: [],
      editorOption: {
        placeholder: 'Texto acá',
      },
      searchText: '',
      product: {
        detail: {},
        flowers: [],
        day: 0,
        description: '',
        video: '',
        status: 1,
        price: null,
        photo1: null,
        photo2: null,
        photo3: null,
        photo4: null,
        reference: null,
        quantity: 1,
      },
    };
  },
  mounted() {
    this.getProducts();
    this.getFlowers();
  },
};
</script>
